import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./FirstSpecialBenefitsSection.module.scss";

export function FirstSpecialBenefitsSection(): React.ReactNode {
  return (
    <>
      <div className="row">
        <div className={"col-12 col-m-4 col-offset-m-4 mg__bottom__m mg__top__l text__center"}>
          <Image
            src="https://asset.basefood.co.jp/lp/common/bnr_benefit_ttl.png"
            alt="初めての方限定3つの初回特典"
            aspectRatio="832 / 231"
            containerClassName={clsx(styles.image, styles.fullImages, styles.meritTtl)}
          />
        </div>
      </div>
      <div className="row">
        <div className={"col-12 col-m-4  col-offset-m-2 mg__bottom__m"}>
          <Image
            src="https://asset.basefood.co.jp/lp/common/bnr_benefit_1.png"
            alt="初めての方限定3つの初回特典"
            aspectRatio="775 / 234"
            containerClassName={clsx(styles.image, styles.fullImages, "mg__bottom__m")}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/common/bnr_benefit_2.png"
            alt="初めての方限定3つの初回特典"
            aspectRatio="775 / 476"
            containerClassName={clsx(styles.image, styles.fullImages)}
          />
        </div>
        <div className={"col-12 col-m-4 mg__bottom__m"}>
          <Image
            src="https://asset.basefood.co.jp/lp/common/bnr_benefit_3.png"
            alt="初めての方限定3つの初回特典"
            aspectRatio="775 / 941"
            containerClassName={clsx(styles.image, styles.fullImages)}
          />
        </div>
      </div>
    </>
  );
}
