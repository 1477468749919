import { Cookies } from "react-cookie";

import { SYS_COOKIE } from "@/configs/system";

import { isClient } from "./base";

/**
 * cookieからアプリのWebViewであるかどうかを判定する
 */
export const getAppWebViewCookieValue = () => {
  const value = isClient
    ? new Cookies().get(SYS_COOKIE.salusAppTokenKey)
    : // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("next/headers").cookies().get(SYS_COOKIE.salusAppTokenKey)?.value;

  return value === SYS_COOKIE.salusAppTokenValue ? value : undefined;
};

export const getIsAppWebView = () => getAppWebViewCookieValue() !== undefined;
